import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CONSTANTS_ROUTING } from '../../constants/router';
import useIsMobile from '../../hooks/use-is-mobile';
import useServices from '../../hooks/use-services';
import { INITIAL_STATE_GLOBAL_PARAMS } from '../../reducers/global-params-reducer';

import CtaOverlay from '../../Components/cta-overlay';
import { DeleteAccountModal } from '../../Components/modal-delete-account';
import { CloseIcon } from '../../Icons';
import { SettingsIcon } from '../../Icons/settings-icon';
import Account from './Components/Account';
import AuthHomeContent from './content';

import { theme } from '../../Style/theme';
import './index.scss';
import { RootState } from '../../reducers';

export enum AuthTab {
    PHOTO_CAPTURE = 'photo_capture',
    TRY_ON_GALLERY = 'try_on_gallery',
}

const AuthHomePage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState<AuthTab>(AuthTab.PHOTO_CAPTURE);
    const [latestUserImageId, setLatestUserImageId] = useState('');
    const { state } = useLocation();

    const tryOnImageUrl = useSelector((state: RootState) => state.userReducer.tryOnResultURL);
    const paramsStore: INITIAL_STATE_GLOBAL_PARAMS = useSelector(
        (state: RootState) => state.globalParamsReducer
    );
    const ctaHasBeenCalled = useSelector((state: RootState) => {
        return state.globalParamsReducer.ctaHasBeenCalled;
    });

    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const nav = useNavigate();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { tryOnService, userService } = useServices();

    const onCtaClose = useCallback(() => {
        dispatch({ type: 'SET_CTA_HAS_BEEN_CALLED', payload: true });
    }, [dispatch]);

    const onChangeTab = (tab: AuthTab) => {
        setCurrentTab(tab);
    };

    const onClickSettings = () => {
        setIsOpen((prev) => !prev);
    };

    const onClickLogout = () => {
        try {
            dispatch({
                type: 'LOGOUT',
            });
            nav(CONSTANTS_ROUTING.HOME);
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    const onClickDeleteAccount = () => {
        setIsOpen(false); // Close the LeftMenu on mobile
        setIsModalOpen(true);
    };

    const fetchUserImage = async () => {
        try {
            dispatch({ type: 'ADD_LOADING' });
            const response = await tryOnService.fetchUserImages();

            if (response.images.length > 0) {
                const lastImage = response.images.pop();
                if (!lastImage) return;
                onCtaClose();
                setLatestUserImageId(lastImage.id);
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({ type: 'REMOVE_LOADING' });
        }
    };

    useEffect(() => {
        const populateUserInfo = async () => {
            const userInfo = await userService.getUserInfo();
            dispatch({ type: 'SET_USER_INFO', payload: userInfo });
        };

        if (latestUserImageId === '' && tryOnImageUrl === null) {
            fetchUserImage();
        }

        populateUserInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const generateTryOn = async () => {
            if (!latestUserImageId) return;

            dispatch({ type: 'ADD_LOADING' });
            try {
                const response = await tryOnService.triggerVirtualTryOn({
                    garment_id: paramsStore.garmentId,
                    partner_id: paramsStore.partnerId,
                    image_id: latestUserImageId,
                    source: 'web',
                });
                dispatch({ type: 'SET_TRY_ON_RESULT', payload: response.output_2D.url });
            } catch (error) {
                console.error(error);
            } finally {
                dispatch({ type: 'REMOVE_LOADING' });
            }
        };

        if (tryOnImageUrl === null && latestUserImageId) {
            generateTryOn();
        }
        //eslint-disable-next-line
    }, [latestUserImageId]);

    useEffect(() => {
        if (!state) return;

        if (state.origin === 'detail') {
            onChangeTab(AuthTab.TRY_ON_GALLERY);
        }
    }, [state]);

    return (
        <div className="AuthHomePage">
            <CtaOverlay show={!ctaHasBeenCalled} onClose={onCtaClose} />
            {isModalOpen && (
                <DeleteAccountModal isModalOpened={isModalOpen} setIsModalOpened={setIsModalOpen} />
            )}
            <div className="header">
                <div className="settings" onClick={onClickSettings}>
                    <SettingsIcon fill={theme['black']} width={20} />
                </div>
                <div className="text-headline-medium">{t('AuthHomePage.title_auth_home_page')}</div>

                {isMobile ? <CloseIcon /> : <div />}
            </div>
            {!isOpen && !isMobile ? (
                <AuthHomeContent
                    onChangeTab={onChangeTab}
                    currentTab={currentTab}
                    resultImage={tryOnImageUrl}
                />
            ) : isMobile ? (
                <AuthHomeContent
                    onChangeTab={onChangeTab}
                    currentTab={currentTab}
                    resultImage={tryOnImageUrl}
                />
            ) : null}
            <Account
                isOpen={isOpen}
                onClickDeleteAccount={onClickDeleteAccount}
                onClickLogout={onClickLogout}
                onClickSettings={onClickSettings}
                onClickOverlay={onClickSettings}
            />
        </div>
    );
};

export default AuthHomePage;
