import { toast } from 'react-toastify';
import Service from '..';
import { CONSTANTS } from '../../constants/global';
import translateErrorMessage from '../../Utils/api-errors';

const SOURCE = 'web';
const POLLING_TIME_LIMIT = 20000;

export interface TriggerVirtualTryOn {
    garment_id: string;
    source?: string;
    image_id: string;
    partner_id: string;
}

export interface TryOnStatusDTO {
    request_id: string;
    status: string;
    output_2D: {
        created: string;
        content_type: string;
        size: number;
        url: string;
    };
}
interface BaseImage {
    id: string;
    content_type: string;
    url: string;
    size: number;
    height: number;
    width: number;
    created: string;
    created_by: string;
    updated: string;
    updated_by: string;
    request_id: string;
    user_id: string;
    data: string;
}

export interface UserImage extends BaseImage {
    hash: string;
}

export interface TryOnImage extends BaseImage {
    input_image_id: string;
    input_image_hash: string;
    model_version: string;
    garment_id: string;
    request_id: string;
}

export interface fetchUserImagesDTO {
    request_id: string;
    images: UserImage[];
}

export interface fetchUserTryOnsDTO {
    request_id: string;
    images: TryOnImage[];
}

enum StatusFlowDto {
    INPROGRESS = 'INPROGRESS',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
}

class TryOnService extends Service {
    async triggerVirtualTryOn(request: TriggerVirtualTryOn): Promise<TryOnStatusDTO> {
        request.source = SOURCE;
        const response = await this.post('/v2/store-experience/tryon', request, true);

        if (!response) {
            throw new Error('No response');
        }

        const responseData = await response.json();

        if (response.ok === false) {
            const errorMessage: string = responseData.errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        const pollResponse = await this.pollTryOnStatus(responseData.request_id);

        return pollResponse;
    }

    async fetchUserImages(): Promise<fetchUserImagesDTO> {
        const response = await this.get('/v2/store-experience/user-images', true);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async fetchUserTryOns(partnerId: string): Promise<fetchUserTryOnsDTO> {
        const response = await this.get(`/v1/user-assets/tryons?partnerID=${partnerId}`, true);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async pollTryOnStatus(request_id: string): Promise<TryOnStatusDTO> {
        let timedOut = false;
        if (!request_id) throw new Error('No requestId provided for polling');
        return new Promise((resolve, reject) => {
            const intervalId = setInterval(async () => {
                try {
                    const response = await this.getTryonStatus(request_id);
                    if (response.status === StatusFlowDto.COMPLETE) {
                        clearInterval(intervalId);
                        resolve(response);
                    } else if (timedOut) {
                        clearInterval(intervalId);
                        reject(new Error('Timeout'));
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    reject(error);
                }
            }, Number(CONSTANTS.CONFIGURATION.INTERVAL_TIME_POLL_MS) || CONSTANTS.DEFAULT_POLLING_INTERVAL);
            setTimeout(() => {
                timedOut = true;
            }, POLLING_TIME_LIMIT);
        });
    }

    async getTryonStatus(request_id: string): Promise<TryOnStatusDTO> {
        const response = await this.get(`/v1/user-assets/tryon/${request_id}`, true);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }

        return await response.json();
    }

    async deleteTryOn(request_id: string): Promise<void> {
        const response = await this.delete(`/v1/user-assets/tryon/${request_id}`, true);

        if (!response) {
            throw new Error('No response');
        }

        if (response.ok === false) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;
            toast.error(translateErrorMessage(errorMessage).translatedError);
            throw new Error(errorMessage);
        }
    }
}

export default TryOnService;
