import { CONSTANTS } from '../constants/global';
import { SUPPORTED_LANGUAGES } from '../constants/language';

class Service {
    public async wrapperTryCatch<T>(func: () => Promise<T>) {
        try {
            const result = await func();
            return result;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    public async get(url: string, requiresAuth?: boolean, acceptLanguage?: SUPPORTED_LANGUAGES) {
        let header: any = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (acceptLanguage) {
            header.headers['Accept-Language'] = acceptLanguage;
        }

        if (requiresAuth) {
            const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
            if (accessToken) {
                header.headers['X-Cognito-Auth'] = JSON.parse(accessToken);
            }
        }

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);

            return response;
        });
    }

    public async delete(url: string, isAuthentifiedCall?: boolean, body?: any) {
        let header: any = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (body) {
            header.body = JSON.stringify(body);
        }

        if (isAuthentifiedCall) {
            const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
            if (accessToken) {
                header.headers['X-Cognito-Auth'] = JSON.parse(accessToken);
            }
        }

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);
            return response;
        });
    }

    public async post(url: string, body: any, requiresAuth?: boolean) {
        let header: any = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (requiresAuth) {
            const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
            if (accessToken) {
                header.headers['X-Cognito-Auth'] = JSON.parse(accessToken);
            }
        }

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);

            return response;
        });
    }

    public async put(url: string, body: any) {
        let header: any = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);

            return response;
        });
    }

    public async multiPartPost(url: string, body: FormData) {
        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, {
                method: 'POST',
                body,
            });

            return response;
        });
    }

    public async patch(url: string, body: any, customHeaders?: any) {
        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, {
                method: 'PATCH',
                body: JSON.stringify(body),
                headers: customHeaders ? customHeaders : { 'Content-Type': 'application/json' },
            });

            return response;
        });
    }

    private getApiBase = (): string | undefined => {
        const isDev =
            window.location.hostname.includes('dev.') ||
            window.location.hostname.includes('localhost');
        if (isDev) {
            return CONSTANTS.API_URL.DEV;
        }

        const isStage =
            window.location.hostname.includes('stage.') ||
            window.location.hostname.includes('staging.');
        if (isStage) {
            return CONSTANTS.API_URL.STAGE;
        }

        return CONSTANTS.API_URL.PROD;
    };
}

export default Service;

export enum ApiBase {
    spree,
    temp,
}
