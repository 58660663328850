import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DownloadIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <span role="img" className="anticon" {...props}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M16.6667 13.3333H20L14 20L8 13.3333H11.3333V6H16.6667V13.3333ZM18.6667 18.6667V20.6667H9.33333V18.6667H8V22H20V18.6667H18.6667Z"
                    fill={props.fill}
                />
            </svg>
        </span>
    );
};

export default DownloadIcon;
