import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthTab } from '.';
import Button from '../../Components/button';
import Radio from '../../Components/radio';
import { CameraButtonIcon } from '../../Icons/camera-button-icon';
import { ShareButtonIcon } from '../../Icons/share-buttton-icon';
import AuthHomeEmptyView from './Components/EmptyView';
import AutHomeGallery from './Components/Gallery';

import { CONSTANTS_ROUTING } from '../../constants/router';
import useIsMobile from '../../hooks/use-is-mobile';
import UtilsParent from '../../Utils/parent';
import useImageShare from '../../hooks/use-image-share';
import DownloadIcon from '../../Icons/download-icon';

interface AuthHomeContentProps {
    onChangeTab: (id: AuthTab) => void;
    currentTab: AuthTab;
    resultImage: string | null;
}

const AuthHomeContent: React.FC<AuthHomeContentProps> = ({
    onChangeTab,
    currentTab,
    resultImage,
}) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const nav = useNavigate();
    const isShareSupported = navigator?.share !== undefined;
    const { handleShare, isMobileDevice } = useImageShare();

    const onClickClose = () => {
        try {
            UtilsParent.sendToParent(UtilsParent.MessageCode.IFrameClose);
        } catch (error) {
            console.error(error);
            toast.error(t('ToastError.error_close_iframe') as string);
        }
    };

    const onClickCamera = () => {
        nav(CONSTANTS_ROUTING.DEEP_LINK);
    };

    return (
        <React.Fragment>
            <div>
                <Radio
                    options={[
                        {
                            value: AuthTab.PHOTO_CAPTURE,
                            label: resultImage
                                ? t('AuthHomePage.tab_result')
                                : t('AuthHomePage.tab_photo'),
                            isSelected: currentTab === AuthTab.PHOTO_CAPTURE,
                        },
                        {
                            value: AuthTab.TRY_ON_GALLERY,
                            label: t('AuthHomePage.tab_tryon'),
                            isSelected: currentTab === AuthTab.TRY_ON_GALLERY,
                        },
                    ]}
                    onChange={onChangeTab}
                />
            </div>

            {currentTab === AuthTab.PHOTO_CAPTURE ? (
                <>
                    {resultImage === null ? (
                        <div className="wrapper-content-empty">
                            <AuthHomeEmptyView />
                        </div>
                    ) : (
                        <div
                            className={isMobile ? `wrapper-content-mobile` : `wrapper-content-full`}
                        >
                            <div className="icons">
                                <CameraButtonIcon
                                    className="icon camera-icon"
                                    fill="#000"
                                    onClick={onClickCamera}
                                />
                                {isShareSupported ? (
                                    isMobileDevice() ? (
                                        <ShareButtonIcon
                                            className="icon"
                                            onClick={() => handleShare(resultImage)}
                                        />
                                    ) : (
                                        <div className="download-circle">
                                            <DownloadIcon
                                                className="icon"
                                                fill="#1B1D1E"
                                                onClick={() => handleShare(resultImage)}
                                            />
                                        </div>
                                    )
                                ) : null}
                            </div>
                            <img
                                src={resultImage}
                                alt="Try On result"
                                className="img"
                                key={resultImage}
                                loading="lazy"
                            />

                            <Button
                                onClick={onClickClose}
                                title={t('AuthHomePage.continue_shopping')}
                                type="tonal"
                            />
                        </div>
                    )}
                </>
            ) : null}

            {currentTab === AuthTab.TRY_ON_GALLERY ? <AutHomeGallery /> : null}
        </React.Fragment>
    );
};

export default AuthHomeContent;
