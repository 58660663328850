import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const useImageShare = () => {
    const { t } = useTranslation();

    const isMobileDevice = useCallback(() => {
        const userAgent = navigator.userAgent;
        return /android|iphone|ipad|ipod/i.test(userAgent);
    }, []);

    const handleShare = useCallback(
        async (imageUrl: string) => {
            if (!imageUrl) {
                console.error('No image URL provided');
                return;
            }

            try {
                const response = await fetch(imageUrl);
                const typeOfImage = response.headers.get('content-type');
                const blob = await response.blob();
                const file = new File([blob], 'TryOn.png', {
                    type: (typeOfImage as string) || 'image/png',
                });

                if (
                    isMobileDevice() &&
                    navigator.canShare &&
                    navigator.canShare({ files: [file] })
                ) {
                    await navigator.share({
                        files: [file],
                        title: 'Try On Image',
                    });
                } else {
                    // Fallback: file for download
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'TryOn.png';
                    link.click();
                }
            } catch (error: any) {
                if (error.name === 'AbortError') {
                    console.log('Share was aborted by the user.');
                } else {
                    console.error('Error sharing the image:', error);
                    toast.error(t('Errors.failed_sharing') as string);
                }
            }
        },
        [t, isMobileDevice]
    );

    return { handleShare, isMobileDevice };
};

export default useImageShare;
